<template>
  <transition name="fade">
    <div class="lds-ring" v-if="visible">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CuPreloader',
  props: {
    visible: Boolean,
  }
}
</script>

<style lang="scss">
@import '../assets/custom.scss';
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  @media (max-width: 1440px){
    @include width-a(80);
    @include height-a(80);
  }
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  @media (max-width: 1440px){
    @include width-a(64);
    @include height-a(64);
    @include margin-a(8,8,8,8);
    @include border-width-a(8);
  }
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
